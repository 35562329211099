import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.prototype.$http = axios;

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB8VRcxp1A3juCNT-w6O1EgCct560Tie6c',
    libraries: 'places',
  },
})


const protectedRoutes = [
  '/orders',
  // '/shopnew'
  // Add other routes that need authorization here
];

router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const isProtectedRoute = protectedRoutes.includes(to.path);

  if (token && isProtectedRoute) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  next();
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
