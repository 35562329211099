import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard.vue')
  },
  {
    path: '/overview',
    name: 'overview',
    component: () => import('@/views/overview.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/categories.vue')
  },
  {
    path: '/changepicture/:id',
    name: 'changePicture',
    component: () => import('@/views/changePicture.vue')
  },
  {
    path: '/shoplist',
    name: 'shoplist',
    component: () => import('@/views/shoplist.vue')
  },
  {
    path: '/shoplist/:id',
    name: 'shoplist2',
    component: () => import('@/views/shoplist.vue')
  },
  {
    path: '/banners',
    name: 'Banners',
    component: () => import('@/views/banners.vue')
  },
  {
    path: '/bannersnew',
    name: 'New Banners',
    component: () => import('@/views/bannersnew.vue')
  },
  {
    path: '/bannersedit/:id',
    name: 'Bannersedit',
    component: () => import('@/views/bannersedit.vue')
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import('@/views/drivers.vue')
  },
  {
    path: '/drivernew',
    name: 'New Driver',
    component: () => import('@/views/drivernew.vue')
  },
  {
    path: '/driveredit/:id',
    name: 'Driveredit',
    component: () => import('@/views/driveredit.vue')
  },
  {
    path: '/bannersimageedit/:id',
    name: 'Bannersedit',
    component: () => import('@/views/bannersimageedit.vue')
  },
  {
    path: '/categorymanagement',
    name: 'Category Management',
    component: () => import('@/views/categorymanagement.vue')
  },
  {
    path: '/categorymanagementimageedit/:id',
    name: 'Category Management',
    component: () => import('@/views/categorymanagementimageedit.vue')
  },
  {
    path: '/categorymanagementedit/:id',
    name: 'Category Management',
    component: () => import('@/views/categorymanagementedit.vue')
  },
  {
    path: '/categorymanagementnew',
    name: 'Category Management',
    component: () => import('@/views/categorymanagementnew.vue')
  },
  {
    path: '/shopnew',
    name: 'shopnew',
    component: () => import('@/views/shopnew.vue')
  },
  {
    path: '/shopedit/:id',
    name: 'shopedit',
    component: () => import('@/views/shopedit.vue')
  },
  {
    path: '/itemslist',
    name: 'itemslist',
    component: () => import('@/views/itemslist.vue')
  },
  // {
  //   path: '/itemslist/:group/:id',
  //   name: 'itemslist',
  //   component: () => import('@/views/itemslist.vue')
  // },
  {
    path: '/itemslistsearch/:id',
    name: 'itemslistsearch',
    component: () => import('@/views/itemslist.vue')
  },
  {
    path: '/itemslistsearchshop/:id',
    name: 'itemslistsearchshop',
    component: () => import('@/views/itemslist.vue')
  },
  {
    path: '/itemslist/:id',
    name: 'itemslist2',
    component: () => import('@/views/itemslist.vue')
  },
  {
    path: '/itemsedit/:id',
    name: 'itemsedit',
    component: () => import('@/views/itemsedit.vue')
  },
  {
    path: '/itemnew',
    name: 'itemnew',
    component: () => import('@/views/itemnew.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders.vue')
  },
  {
    path: '/invoice/:id',
    name: 'invoice',
    component: () => import('@/views/invoice.vue')
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/views/notification.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
